/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import Glide from "@glidejs/glide";
import Img from "gatsby-image";
import Plx from "react-plx";
import { AppContext } from "~context/AppContext";
import SideText from "~components/SideText";

import quotes from "~assets/images/icons/quotes.svg";

class AboutLayoutComponent extends Component {
  cachedDevice;

  glide;

  glideRef = React.createRef();

  glideRetries = 0;

  componentDidMount() {
    const { appContext } = this.props;

    this.cachedDevice = appContext?.device;

    if (document) {
      document.addEventListener(`resize`, this.handleResize, false);
    }

    setTimeout(() => {
      this.glideMount(true);
    });
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener(`resize`, this.handleResize, false);
    }
  }

  handleResize = () => {
    const { appContext } = this.props;

    if (this.cachedDevice !== appContext?.device) {
      this.cachedDevice = appContext?.device;

      this.glideMount();
    }
  };

  //

  glideMount = retry => {
    if (this.glide) {
      return;
    }

    if (!this.glideRef || !this.glideRef.current) {
      if (retry) {
        this.glideRetries += 1;

        setTimeout(() => {
          this.glideMount(this.glideRetries < 3);
        }, 1000);
      }

      return;
    }

    this.glide = new Glide(`.index-page__glide`, {
      autoplay: 5000,
      hoverpause: false,
      gap: 0,
      perView: 1,
      type: `carousel`
    }).mount();
  };

  //

  render() {
    const { appContext, bannerOnly, frontmatter, onSale } = this.props;

    //

    const bannerJSX = (
      <section className="index-page__section index-page__section--intro relative flex items-center justify-center pt-64 pb-32 bg-orange">
        {appContext?.device === `desktop` && (
          <SideText color="white" textLeft="ABOUT" />
        )}

        <article className="grid relative">
          <div className="grid-end-8 sm:grid-end-12 grid-start-3 sm:grid-start-1 relative">
            {appContext?.device !== `desktop` && (
              <SideText color="white" textLeft="ABOUT" />
            )}

            <h1
              className="index-page__section__heading animation-appear f2"
              style={{ animationDelay: `200ms` }}
            >
              {frontmatter.heading}
            </h1>
          </div>

          <div className="index-page__section--intro__quote grid-end-7 sm:grid-end-10 grid-start-5 sm:grid-start-2 relative mt-32">
            <img
              className="index-page__quotes animation-appear-right absolute top-0 left-0"
              style={{ animationDelay: `500ms` }}
              src={quotes}
              alt="Quotation marks"
            />

            <p
              className="animation-appear-top relative b1 font-light"
              style={{ animationDelay: `300ms` }}
            >
              {frontmatter.intro}”
            </p>

            <p
              className="animation-appear mt-6 f5 font-bold"
              style={{ animationDelay: `500ms` }}
            >
              {frontmatter.introAuthor}
            </p>
          </div>
        </article>
      </section>
    );

    if (bannerOnly || (onSale && appContext?.device !== `desktop`)) {
      return bannerJSX;
    }

    return (
      <>
        {bannerJSX}

        {appContext?.device === `desktop` &&
          frontmatter?.galleryImages?.[0] && (
            <section className="index-page__section relative">
              <div
                ref={this.glideRef}
                className="index-page__glide glide relative"
              >
                <div className="glide__track relative" data-glide-el="track">
                  <ul className="glide__slides relative">
                    {frontmatter.galleryImages.map((galleryImage, index) => {
                      const galleryIndex = index;

                      if (!galleryImage?.image?.childImageSharp) {
                        return <></>;
                      }

                      return (
                        <li
                          key={`gallery-item-${galleryIndex}`}
                          className="relative block"
                        >
                          <div className="index-page__glide__container relative">
                            <Img
                              className="w-full relative block"
                              fluid={galleryImage.image.childImageSharp.fluid}
                              alt={`Gallery Item ${galleryIndex}`}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div
                  className="glide__bullets relative flex justify-end pt-6"
                  data-glide-el="controls[nav]"
                >
                  {frontmatter.galleryImages.map((image, index) => {
                    const buttonIndex = index;

                    return (
                      <button
                        key={`glide-button-${buttonIndex}`}
                        type="button"
                        className="glide__bullet relative block ml-4"
                        data-glide-dir={`=${buttonIndex}`}
                      >
                        <span className="opacity-0">{buttonIndex}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            </section>
          )}

        <section className="index-page__section index-page__artist relative pt-64">
          <article className="grid relative">
            <div className="grid-end-5 sm:grid-end-10 grid-start-3 sm:grid-start-2 relative">
              <img
                className="index-page__quotes absolute top-0 left-0"
                src={quotes}
                alt="Sampa The Great"
              />

              <p className="relative f5 font-light">
                {frontmatter.artist1Quote}”
              </p>

              <p className="mt-6 f5 font-bold">{frontmatter.artist1Author}</p>
            </div>

            {frontmatter?.artist1?.childImageSharp && (
              <Plx
                className="index-page__artist__image index-page__artist__image--0 grid-end-4 sm:grid-end-10 grid-start-9 sm:grid-start-3 relative"
                parallaxData={[
                  {
                    start: `self`,
                    duration: appContext.windowHeight * 1.5 || 1,
                    properties: [
                      {
                        startValue: 0,
                        endValue: appContext.windowWidth * -0.15 || 1,
                        property: `translateY`
                      }
                    ]
                  }
                ]}
              >
                <Img
                  className="w-full relative"
                  fluid={frontmatter.artist1.childImageSharp.fluid}
                  alt="Feature One"
                />
              </Plx>
            )}
          </article>

          {frontmatter?.artistVideo && (
            <div className="grid pt-12 pb-8">
              <div className="index-page__iframe grid-end-6 sm:grid-end-12 grid-start-4 sm:grid-start-1 relative" dangerouslySetInnerHTML={{ __html: frontmatter.artistVideo }} />
            </div>
          )}
        </section>

        <section className="index-page__section index-page__gallery relative flex flex-wrap items-start py-32">
          {frontmatter?.feature1?.childImageSharp && (
            <Plx
              className="index-page__gallery__image index-page__gallery__image--0 w-1/2 relative block"
              parallaxData={[
                {
                  start: `self`,
                  duration: appContext.windowHeight * 1.5 || 1,
                  properties: [
                    {
                      startValue: 0,
                      endValue: appContext.windowWidth * -0.08 || 1,
                      property: `translateY`
                    }
                  ]
                }
              ]}
            >
              <Img
                className="w-full relative block"
                fluid={frontmatter.feature1.childImageSharp.fluid}
                alt="Feature 1"
              />
            </Plx>
          )}

          {frontmatter?.feature2?.childImageSharp && (
            <Plx
              className="index-page__gallery__image index-page__gallery__image--1 w-1/2 relative block"
              parallaxData={[
                {
                  start: `self`,
                  duration: appContext.windowHeight * 1.5 || 1,
                  properties: [
                    {
                      startValue: 0,
                      endValue: appContext.windowWidth * -0.15 || 1,
                      property: `translateY`
                    }
                  ]
                }
              ]}
            >
              <Img
                className="w-full relative block"
                fluid={frontmatter.feature2.childImageSharp.fluid}
                alt="Feature 2"
              />
            </Plx>
          )}

          {frontmatter?.feature3?.childImageSharp && (
            <Plx
              className="index-page__gallery__image index-page__gallery__image--2 w-1/2 relative block"
              parallaxData={[
                {
                  start: `self`,
                  duration: appContext.windowHeight * 1.5 || 1,
                  properties: [
                    {
                      startValue: 0,
                      endValue: appContext.windowWidth * -0.15 || 1,
                      property: `translateY`
                    }
                  ]
                }
              ]}
            >
              <Img
                className="w-full relative block"
                fluid={frontmatter.feature3.childImageSharp.fluid}
                alt="Feature 3"
              />
            </Plx>
          )}

          {frontmatter?.feature4?.childImageSharp && (
            <Plx
              className="index-page__gallery__image index-page__gallery__image--3 relative block"
              parallaxData={[
                {
                  start: `self`,
                  duration: appContext.windowHeight * 1.5 || 1,
                  properties: [
                    {
                      startValue: 0,
                      endValue: appContext.windowWidth * -0.1 || 1,
                      property: `translateY`
                    }
                  ]
                }
              ]}
            >
              <Img
                className="w-full relative block"
                fluid={frontmatter.feature4.childImageSharp.fluid}
                alt="Feature 4"
              />
            </Plx>
          )}
        </section>

        <section className="index-page__section relative">
          <article className="grid relative">
            <div className="grid-end-4 sm:grid-end-10 grid-start-3 sm:grid-start-2 relative f4 font-light">
              <img
                className="index-page__quotes absolute top-0 left-0"
                src={quotes}
                alt="Quotation marks"
              />

              <p className="relative f5 font-light">
                {frontmatter.artist2Quote}”
              </p>

              <p className="index-page__section__author mt-6 pb-32 f5 font-bold">
                {frontmatter.artist2Author}
              </p>
            </div>

            {frontmatter?.artist2?.childImageSharp && (
              <div className="index-page__feature index-page__feature--1 grid-end-4 sm:grid-end-10 grid-start-8 sm:grid-start-2 relative flex items-end">
                <Img
                  className="w-full relative"
                  fluid={frontmatter.artist2.childImageSharp.fluid}
                  alt="Tropical Fuck Storm"
                />
              </div>
            )}
          </article>
        </section>

        {frontmatter?.aboutVideo && frontmatter.aboutVideo !== `` && (
          <>
            {(appContext?.device === `desktop` && (
              <section className="grid">
                <div className="grid-end-8 grid-start-3 mt-32 mb-32">
                  <div className="event-page__youtube w-full relative">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.aboutVideo
                      }}
                    ></div>
                  </div>
                </div>
              </section>
            )) || (
              <section className="w-full relative mt-24 mb-24">
                <div className="event-page__youtube w-full relative">
                  <div
                    dangerouslySetInnerHTML={{ __html: frontmatter.aboutVideo }}
                  ></div>
                </div>
              </section>
            )}
          </>
        )}
      </>
    );
  }
}

const AboutLayout = ({ bannerOnly, frontmatter, onSale }) => {
  const appContext = useContext(AppContext);

  return (
    <AboutLayoutComponent
      appContext={appContext}
      bannerOnly={bannerOnly}
      frontmatter={frontmatter}
      onSale={onSale}
    />
  );
};

export default AboutLayout;
