/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import SideText from "~components/SideText";

class OnSaleEventsComponent extends Component {
  componentDidMount() {}

  //

  render() {
    const { appContext, events } = this.props;

    //

    return (
      <>
        {events && events.length > 0 && (
          <section className="featured-events w-full relative flex items-center justify-center py-32 bg-black">
            <SideText color="black" textLeft="ON SALE" />

            <div className="grid relative">
              <ul className="featured-events__inner grid-end-10 sm:grid-end-9 grid-start-3 relative">
                {events.map((event, index) => {
                  const eventIndex = index;

                  return (
                    <li key={`event-${eventIndex}`}>
                      <Link
                        to={event.fields.slug}
                        className="featured-events__link animation-appear relative flex items-end justify-start my-4 whitespace-no-wrap"
                        style={{
                          animationDelay: `${eventIndex * 100 + 100}ms`
                        }}
                      >
                        <h3 className="featured-events__title relative z-10 f3 text-white">
                          {event.frontmatter.title}
                        </h3>

                        <h5 className="featured-events__artist relative z-10 ml-4 b1 uppercase text-white">
                          {event.frontmatter.artist}
                        </h5>

                        {appContext.device === `desktop` &&
                          event.frontmatter.image &&
                          event.frontmatter.image.childImageSharp && (
                            <div className="featured-events__hover-img absolute transform-center flex items-center justify-center pointer-events-none">
                              <Img
                                className="w-full relative block"
                                fluid={
                                  event.frontmatter.image.childImageSharp.fluid
                                }
                                alt={event.frontmatter.title}
                              />
                            </div>
                          )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>
        )}
      </>
    );
  }
}

const OnSaleEvents = ({ events }) => (
  <AppContext.Consumer>
    {appContext => (
      <OnSaleEventsComponent appContext={appContext} events={events} />
    )}
  </AppContext.Consumer>
);

export default OnSaleEvents;
