/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import AboutLayout from "~components/AboutLayout";
import CursorDistorter from "~components/CursorDistorter";
import OnSaleEvents from "~components/OnSaleEvents";
import Layout from "~components/Layout";
import PastEvents from "~components/PastEvents";
import SEO from "~components/SEO";
import SideText from "~components/SideText";
import { getRandomIntByRange } from "~utils/helpers";

const IndexPage = ({ data, location }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [blendImageIndex, setBlendImageIndex] = useState(null);

  // ---------------------------------------------------------------------------
  // variables

  const { frontmatter } = data.markdownRemark;
  const events = [];

  data.allMarkdownRemark.edges.forEach(edge => {
    events.push(edge.node);
  });

  const saleEvents = [];

  events.forEach(event => {
    if (event.frontmatter.onSale && event.frontmatter.onSale === true) {
      saleEvents.push(event);
    }
  });
  
  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const { blendImagesLeft } = frontmatter;

    const randomIndex = getRandomIntByRange(0, blendImagesLeft.length - 1);

    setBlendImageIndex(randomIndex);
  }, []);
  
  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="index-page relative">
        <section
          className="index-page__section index-page__section--banner relative"
          style={{ backgroundColor: `#ffffff` }}
        >
          <SideText
            color="white"
            textLeft="FILM"
            textRight="MUSIC"
            translatable
          />

          {blendImageIndex !== null && (
            <CursorDistorter
              imageLeft={
                frontmatter?.blendImagesLeft?.[blendImageIndex]?.image?.childImageSharp?.fluid?.src
              }
              imageLeftXS={
                frontmatter?.blendImagesLeftXS?.[blendImageIndex]?.image?.childImageSharp?.fluid?.src
              }
              imageRight={
                frontmatter?.blendImagesRight?.[blendImageIndex]?.image?.childImageSharp?.fluid?.src
              }
              imageRightXS={
                frontmatter?.blendImagesRightXS?.[blendImageIndex]?.image?.childImageSharp?.fluid?.src
              }
            />
          )}
        </section>

        {saleEvents.length > 0 && <OnSaleEvents events={saleEvents} />}

        {/* todo: fix rehydration issues */}
        <AboutLayout frontmatter={frontmatter} />

        <PastEvents />
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        introAuthor
        artist1Author
        artist1Quote
        artist2Author
        artist2Quote
        artist1 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        artistVideo
        artist2 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        galleryImages {
          image {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        feature1 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        feature2 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        feature3 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        feature4 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        blendImagesLeft {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        blendImagesRight {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        blendImagesLeftXS {
          image {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        blendImagesRightXS {
          image {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "event-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            artist
            location
            onSale
            
            ticketUrls {
              region
              url
            }
    
            ticketUrl
            timezone
            endDate
            times
            color
            fontColor
            description
            detailsFilm
            detailsMusic
            quote
            quoteAuthor
            quotationMarks
            quotationStars
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            galleryUpperLeft {
              childImageSharp {
                fluid(maxWidth: 960, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            galleryUpperRight {
              childImageSharp {
                fluid(maxWidth: 960, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            galleryMiddleRight {
              childImageSharp {
                fluid(maxWidth: 960, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            galleryLowerLeft {
              childImageSharp {
                fluid(maxWidth: 960, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            galleryLowerRight {
              childImageSharp {
                fluid(maxWidth: 960, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
