/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import createShader from "standalone-shader";
import anime from "animejs";
import { useWindowDimensions } from "~hooks";
import { ieDetector } from "~utils/screen";

import cursorDisplacement from "~assets/images/webgl/cursor-displacement.jpg";

const CursorDistorter = ({ imageLeft, imageRight, imageLeftXS, imageRightXS }) => {
  //----------------------------------------------------------------------------
  // Microsoft / SSR bailout

  const isClient = typeof window !== `undefined`;
  const ie = ieDetector();

  //----------------------------------------------------------------------------
  // consts

  const canvasRef = useRef();
  const containerRef = useRef();
  
  const [activeAnime, setActiveAnime] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [device, setDevice] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [shader, setShader] = useState(false);
  const [started, setStarted] = useState(false);
  
  const [listenersAttached, setListenersAttached] = useState(false);

  const windowSize = useWindowDimensions();

  //----------------------------------------------------------------------------
  // variables

  const fragmentShader = `
    precision highp float;
    uniform vec2 resolution;
    uniform sampler2D dispmap;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform float progress;
    uniform float intensity;
    uniform float angle1;
    uniform float angle2;

    mat2 rotation(float angle) {
      float s = sin(angle);
      float c = cos(angle);
      return mat2(c, -s, s, c);
    }

    void main() {          
      vec2 p = gl_FragCoord.xy / resolution.xy;
      vec2 disp = texture2D(dispmap, p).rg;
      vec2 pos1 = p + rotation(angle1) * disp * intensity * progress;
      vec2 pos2 = p + rotation(angle2) * disp * intensity * (1.0 - progress);
      vec4 color1 = texture2D(texture1, pos1);
      vec4 color2 = texture2D(texture2, pos2);

      gl_FragColor = mix(color1, color2, progress);
    }
  `;

  //----------------------------------------------------------------------------
  // methods

  const drawImage = (context, img, width, height) => {
    context.canvas.width = width;
    context.canvas.height = height;

    context.drawImage(img, 0, 0, width, height);
  };

  const animeReset = () => {
    if (!activeAnime || !loaded || !shader || !started) {
      return;
    }

    anime.remove(shader.uniforms.progress);

    anime({
      targets: shader.uniforms.progress,
      value: 0,
      duration: 100,
      easing: `easeOutExpo`
    });

    setActiveAnime(null);
  };

  //----------------------------------------------------------------------------
  // lifecycle - init

  useEffect(() => {
    if (!isClient) {
      return () => {};
    }

    const handleResize = () => {
      if (!isClient) {
        return;
      }
 
      const width = window.innerWidth;

      let detectedDevice = `desktop`;
  
      if (
        width > 768 && width < 1024
      ) {
        detectedDevice = `tablet`;
      } else if (width <= 768) {
        detectedDevice = `mobile`;
      }
  
      setDevice(detectedDevice);
    };
  
    const handleScroll = () => {
      if (!isClient) {
        return;
      }
  
      setScrollTop(window.pageYOffset);
    };

    //

    handleResize();

    window.addEventListener(`resize`, handleResize, false);
    window.addEventListener(`scroll`, handleScroll, false);

    return () => {
      window.removeEventListener(`resize`, handleResize, false);
      window.removeEventListener(`scroll`, handleScroll, false);
    };
  }, []);

  //----------------------------------------------------------------------------
  // lifecycle - DOM ready

  useEffect(() => {
    if (!canvasRef?.current || loaded || !device) {
      return;
    }

    setLoaded(true);
    
    const canvasBoundingRect = canvasRef.current.getBoundingClientRect();
    const { width, height } = canvasBoundingRect;

    const texture1 = document.createElement(`canvas`).getContext(`2d`);
    const texture2 = document.createElement(`canvas`).getContext(`2d`);

    const dispmap = new Image();
    const image1 = new Image();
    const image2 = new Image();
    const image1XS = new Image();
    const image2XS = new Image();

    const canvasShader = createShader({
      canvas: canvasRef.current,
      dpr: window.devicePixelRatio,
      uniforms: {
        progress: {
          type: `float`,
          value: 0
        },
        dispmap: {
          type: `sampler2D`,
          value: dispmap,
          flipY: true
        },
        texture1: {
          type: `sampler2D`,
          value: texture1.canvas,
          flipY: true
        },
        texture2: {
          type: `sampler2D`,
          value: texture2.canvas,
          flipY: true
        },
        intensity: {
          type: `float`,
          value: 0.7
        },
        angle1: {
          type: `float`,
          value: Math.PI / 4
        },
        angle2: {
          type: `float`,
          value: -(Math.PI / 4) * 3
        }
      },
      fragmentShader
    });

    //

    dispmap.onload = () => canvasShader.uniforms.dispmap.update();

    image1.onload = () => {
      if (device !== `desktop`) {
        return;
      }

      drawImage(texture1, image1, width, height);

      canvasShader.uniforms.texture1.update();
    };

    image2.onload = () => {
      if (device !== `desktop`) {
        return;
      }

      drawImage(texture2, image2, width, height);

      canvasShader.uniforms.texture2.update();
    };
    
    image1XS.onload = () => {
      if (device === `desktop`) {
        return;
      }

      drawImage(texture1, image1XS, width, height);

      canvasShader.uniforms.texture1.update();
    };

    image2XS.onload = () => {
      if (device === `desktop`) {
        return;
      }

      drawImage(texture2, image2XS, width, height);

      canvasShader.uniforms.texture2.update();
    };

    dispmap.src = cursorDisplacement;
    image1.src = imageLeft;
    image2.src = imageRight;
    image1XS.src = imageLeftXS;
    image2XS.src = imageRightXS;

    //

    setCanvas({
      width,
      height
    });

    setShader(canvasShader);
  }, [canvasRef?.current, device, imageLeft, imageRight, imageLeftXS, imageRightXS]);

  //----------------------------------------------------------------------------
  // lifecycle - canvas ready

  useEffect(() => {
    if (!canvas || !device || !shader) {
      return;
    }

    if (!started) {
      setStarted(true);
      
      shader.start();
    }

    shader.resize(canvas.width, canvas.height);
  }, [canvas, device, shader]);

  //----------------------------------------------------------------------------
  // lifecycle - animation

  // todo: trigger animation manually on mobile
  useEffect(() => {
    if (!isClient || !shader?.uniforms || listenersAttached) {
      return () => {};
    }

    setListenersAttached(true);

    const handleMouseMove = (e) => {
      if (!isClient || !shader?.uniforms) {
        return;
      }

      const deltaX = -(0.5 - e.pageX / window.innerWidth);

      let value;

      value = parseFloat(deltaX + 0.5);

      if (value > 0.9) {
        value = 1;
      } else if (value < 0.1) {
        value = 0;
      }

      setActiveAnime(
        anime
          .timeline()
          .add({
            targets: shader.uniforms.progress,
            value,
            duration: 500,
            easing: `easeOutExpo`
          })
          .add({
            targets: shader.uniforms.progress,
            value: value > 0.5 ? 1 : 0,
            duration: 20000,
            easing: `linear`
          })
        );
    }

    //

    window.addEventListener(`mousemove`, handleMouseMove, false);

    return () => {
      window.removeEventListener(`mousemove`, handleMouseMove, false);
    }
  }, [shader]);
  
  //----------------------------------------------------------------------------
  // lifecycle - resize

  useEffect(() => {
    if (
      !containerRef ||
      !containerRef.current ||
      !canvas ||
      !shader
    ) {
      return;
    }

    const containerBoundingRect = containerRef.current.getBoundingClientRect();
    const { width, height } = containerBoundingRect;

    setCanvas({
      ...canvas,
      width,
      height
    });
  }, [windowSize?.height, windowSize?.width]);

  useEffect(() => {
    if (
      !containerRef ||
      !containerRef.current ||
      !canvas ||
      !shader
    ) {
      return;
    }

    animeReset();
    
  }, [device]);

  //----------------------------------------------------------------------------
  // render

  let transform = `translate3d(0, 0, 0)`;

  if (device !== `desktop`) {
    transform = `translate3d(0, ${scrollTop / 3}px, 0)`;
  }

  const containerStyle = {
    transform
  };
  
  if (!isClient || (ie && ie > 10)) {
    return (
      <div className="w-full h-full absolute">
        <img
          className="w-full absolute transform-center"
          src={imageLeft}
          alt="Event Banner"
        />
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      role="presentation"
      className="cursor-distorter w-full h-full absolute gpu"
      style={containerStyle}
    >
      <canvas
        ref={canvasRef}
        className={`absolute top-0 right-0 bottom-0 left-0 pointer-events-none ${
          loaded ? `` : `opacity-0`
        }`}
      />
    </div>
  );
};

export default CursorDistorter;
